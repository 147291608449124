@media (min-width: 769px) {
  /* .login-component .logo-panel {
    width: 40%;
  }

  .login-component .form-panel {
    padding: 1rem;
  } */

  /* .login-logo {
    height: 15rem;
    width: 15rem;
  } */
}

@media (min-width: 1025px) {
  /* .login-component .form-panel {
    width: 70%;
  }

  .login-component .logo-panel {
    width: 30%;
  }

  .forgot-component .form-panel {
    width: 70%;
  }

  .forgot-component .logo-panel {
    width: 30%;
  } */
}

@media (max-width: 1000px) {
  .login-component {
    flex-direction: column;
    flex: 1;
  }

  .login-component .logo-panel {
    /* margin-top: 3rem; */
    height: auto;
  }

  .login-component .form-panel {
    flex: 1;
    height: auto;
  }

  .login-logo {
    visibility: hidden;
  }

  .login-logo-icon-small-screen {
    display: block;
    /* background-image: url(./assets/img/img_logo_login.png) !important; */
    /* background-position: center center; */
    /* max-height: fit-content; */
  }

  .register-form-second-col {
    margin-top: 1rem;
  }

  .forgot-component {
    flex-direction: column;
    flex: 1;
  }

  .forgot-component .logo-panel {
    height: auto;
  }

  .forgot-component .form-panel {
    flex: 1;
    height: auto;
  }

  .forgot-logo {
    visibility: hidden;
  }

  .forgot-logo-icon-small-screen {
    display: block;
  }

  .indicator-container {
    margin-bottom: 0.5rem;
  }

  .logo-top-image {
    margin-left: 0px;
  }

  .member-info-outer-container {
    flex-direction: column;
    background: #f0f1f6;
    padding: 0;
  }

  .terms-condition-box {
    padding-top: 2rem;
    background: #f0f1f6;
  }

  .member-view-outer-container {
    background: transparent
      linear-gradient(
        90deg,
        var(--member-outer-container-gradient-front) 0%,
        var(--member-outer-container-gradient-back) 100%
      )
      0% 0% no-repeat padding-box;
    margin-right: 0;
    padding: 2rem;
  }

  .profile-avatar {
    height: 5rem;
    width: 5rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  .point-outer-container {
    margin: 1rem;
  }

  .tier-outer-container {
    margin: 0rem 1rem 0rem 1rem;
  }

  .profile-info-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .profile-buttons-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .save-button {
    flex: 1;
  }

  .back-button {
    flex: 1;
  }

  .change-password-modal-positive-button {
    flex: 1;
  }

  .change-password-modal-negative-button {
    flex: 1;
  }

  .general-outermost-container {
    padding: 1rem;
  }

  .transaction-title {
    margin-bottom: 1rem;
  }

  .trans-pts-add-container {
    align-self: baseline;
    flex: 0.2;
  }

  .trans-pts-minus-container {
    align-self: baseline;
    flex: 0.2;
  }

  .transaction-component-info-container {
    flex: 0.8;
    margin-right: 1rem;
  }
}
