@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;700;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Mulish', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #24293a;
}

/**************************************************************
common start
***************************************************************/
.modal-button {
  background-color: #af8e56 !important;
  color: #fff !important;
}

.full-width-colored-button {
  background-color: var(--button-background-color) !important;
  color: var(--positive-button-text-color) !important;
  width: 100%;
}

.full-width-bordered-button {
  background-color: #fff !important;
  color: var(--bordered-button-text-color) !important;
  width: 100%;
  border: 1px solid var(--bordered-button-border-color) !important;
}

.colored-button {
  background-color: var(--button-background-color) !important;
  color: var(--positive-button-text-color) !important;
}

.bordered-button {
  background-color: #fff !important;
  color: var(--bordered-button-text-color) !important;
  border: 1px solid var(--bordered-button-border-color) !important;
}

.space-between-field-one {
  margin-top: 1rem;
}

.space-between-field {
  margin-top: 2rem;
}

.space-between-field-three {
  margin-top: 3rem;
}

.space-between-field-three-two-five {
  margin-top: 3.35rem;
}

.space-between-field-four {
  margin-top: 4rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
}

.preloader {
  vertical-align: middle;
  border-radius: 0;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99998;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 300px 200px rgba(255, 255, 255, 0.4);
  margin: auto;
  right: 0;
  bottom: 0;
}
.css-loader {
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid var(--loading-color);
  border-bottom: 5px solid var(--loading-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  position: absolute;
  top: -30px;
  left: -30px;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-text {
  color: red;
  font-size: 0.7rem;
}

.navbar-top-container {
  display: flex;
  flex: 1;
}

.header-nav {
  background-color: var(--header-nav-background);
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.logo-top-image {
  margin: auto;
}

.signout-text {
  color: var(--signout-text-color);
  background: url(./assets/img/signout_icon_white.png) no-repeat;
  padding-left: 1.5rem;
  text-align: end;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.signout-icon {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.general-outermost-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  padding-left: 3.75rem;
  padding-right: 3.75rem;
  /* height: 100vh; */
  flex-direction: column;
  flex: 1;
  /* overflow-y: auto; */
}

.divider-hr-line {
  border-top: 1px solid #f6f6f6;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
/**************************************************************
common end
***************************************************************/

/**************************************************************
login
***************************************************************/
.login-component {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}

.login-component .logo-panel {
  flex: 1;
  display: flex;
  height: 100vh;
}

.login-component .form-panel {
  flex: 1;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  color: #24293a;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100vh;
  overflow-y: auto;
}

.login-logo {
  background-image: url(./assets/img/partners.png) !important;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
  background-size: cover;
  height: 100%;
}

.login-logo-icon {
  display: block;
  margin: auto;
}

.login-logo-icon-small-screen {
  margin: auto;
}

.login-form-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}

.login-title {
  font-weight: 800;
  font-size: 1.625rem;
}

.login-form-title {
  font-weight: 700;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.login-input-control {
}

.login-input-control .form-control {
  border: 1px solid #cad1d7;
  padding-left: 1rem !important;
}

.login-input-control .form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.login-input-control .form-control:focus {
  background: white;
  color: #393939;
  border: 1px solid #cad1d7;
}

.login-form-prepend-text {
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
  border-right: 2px solid #cad1d7 !important;
}

.login-form-prepend-text-error {
  border-right: 2px solid #cad1d7 !important;
  border-color: red !important;
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
}

.login-input-control-append {
}

.login-input-control-append .form-control {
  padding-left: 1rem !important;
}

.login-input-control-append .form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.login-input-control-append .form-control:focus {
  background: white;
  color: #393939;
  border: 1px solid #cad1d7;
}

.login-form-append-text {
  border-left: 0px !important;
  border-color: #cad1d7 !important;
}

.login-form-append-text-error {
  border-left: 0px !important;
  border-color: red !important;
}

.forgot-password-text {
  font-weight: 800;
  font-style: italic;
  font-size: 0.7rem;
  text-align: end;
  margin-right: 1rem;
  margin-top: 0.5rem;
  color: #424755;
  cursor: pointer;
}

.login-button {
  /* @include variable(background-color, --button-background-color, green) !important */
  background-color: var(--button-background-color) !important;
  color: var(--positive-button-text-color) !important;
  width: 100%;
}

.havent-got-account-text {
  font-weight: 700;
  color: #5c6586;
  text-align: center;
  font-size: 0.9rem;
}

.register-now-text {
  font-weight: 800;
  cursor: pointer;
}

.oauth-login-outer-container {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  justify-content: center;
  bottom: 0;

  /* position: fixed;
  bottom: 0;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  background: orange;
  margin-left: auto;
  margin-right: auto;
  flex: 1; */
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  font-weight: 700;
  margin: 8px 0px;
  color: #5c6586;
  font-size: 0.9rem;
}
.hr-sect::before,
.hr-sect::after {
  content: '';
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.oauth-login-icons-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.oauth_icon {
  margin-right: 2rem;
}

/**************************************************************
register start
***************************************************************/
.register-logo {
  margin-top: 4rem;
}
.register-title-container {
  display: flex;
  flex-direction: row;
}

.register-title-inner-layer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1rem;
}

.register-title {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 0.8;
}

.register-desc {
  font-weight: 700;
  font-size: 0.7rem;
  color: #5c6586;
  margin-top: 0.5rem;
}

.register-back-icon {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}

.register-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

select:required:invalid {
  color: #e6e7e9 !important;
  font-weight: 700;
  font-style: italic;
}

option {
  color: #24293a !important;
  font-style: normal;
}

.gender-select-box {
  color: #24293a !important;
}

.gender-box {
  width: 100%;
}

.registration-checkbox-container {
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
}

.registration-checkbox-option-container {
  flex: 1;
}

.registration-checkbox {
  position: inherit !important;
  margin-left: 0.5rem;
}

/************************************************************
tac code start
************************************************************/
.tac-code-image {
  height: auto;
  width: 150px;
  align-self: center;
}

.tac-desc {
  align-self: center;
  text-align: center;
  font-weight: 800;
  font-size: 0.9rem;
}

.code-input-outer-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.code-input {
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  padding: 1rem !important;
  margin-right: 0.8rem;
  height: 3rem !important;
  width: 3rem !important;
  text-align: center;
  line-height: 1 !important;
}

.reset-timer-text {
  font-weight: 800;
  font-size: 0.9rem;
  align-self: center;
}

.confirm-button {
  width: 40% !important;
  align-self: center;
}
/************************************************************
tac code end
************************************************************/

/************************************************************
forgot password start
************************************************************/
.forgot-component {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}

.forgot-component .logo-panel {
  flex: 1;
  display: flex;
  height: 100vh;
}

.forgot-component .form-panel {
  flex: 1;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  color: #24293a;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100vh;
  overflow-y: auto;
}

.forgot-logo {
  background-image: url(./assets/img/partners.png) !important;
  flex: 1;
  justify-content: center;
  text-align: center;
  display: flex;
  background-size: cover;
}

.forgot-title-container {
  display: flex;
  flex-direction: row;
}

.forgot-title-inner-layer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1rem;
}

.forgot-title {
  font: Bold 1.25rem Muli;
  line-height: 0.8;
}

.forgot-back-icon {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}

.forgot-logo-icon {
  display: block;
  margin: auto;
}

.forgot-logo-icon-small-screen {
  /* display: block; */
  margin: auto;
}

.forgot-form-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.forgot-title {
  font-weight: 800;
  font-size: 1.625rem;
}

.forgot-form-title {
  font-weight: 700;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.lable-title {
  margin-bottom: 0px;
}

.forgot-input-control {
}

.forgot-input-control .form-control {
  border: 1px solid #cad1d7;
  padding-left: 1rem !important;
}

.forgot-input-control .form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.forgot-input-control .form-control:focus {
  background: white;
  color: #393939;
  border: 1px solid #cad1d7;
}

.forgot-form-prepend-text {
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
  border-right: 2px solid #cad1d7 !important;
}

.forgot-form-prepend-text-error {
  border-right: 2px solid #cad1d7 !important;
  border-color: red !important;
  font-weight: 700 !important;
  color: #24293a !important;
  font-size: 0.9rem !important;
}

.forgot-input-control-append {
}

.forgot-input-control-append .form-control {
  padding-left: 1rem !important;
}

.forgot-input-control-append .form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
  font-weight: 700;
  font-style: italic;
}

.forgot-input-control-append .form-control:focus {
  background: white;
  color: #393939;
  border: 1px solid #cad1d7;
}

.forgot-form-append-text {
  border-left: 0px !important;
}

.recover-hint {
  font-size: 1.0625rem;
  font-weight: bold;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
}

.forgot-send-button {
  width: 30% !important;
  align-self: center;
}

/************************************************************
forgot password end
************************************************************/

/************************************************************
reset password start
************************************************************/
.modal-header-text {
  justify-content: center !important;
  margin-left: 2rem;
  margin-right: 2rem;
}

.modal-header-text h5 {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  color: #24293a;
}

.modal-body-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #24293a;
  text-align: center;
  font-weight: 600;
}

.modal-footer-container {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.modal-button {
  align-self: center !important;
  width: 65%;
  background-color: #af8e56 !important;
  color: #fff !important;
}

/************************************************************
reset password end
************************************************************/

/************************************************************
password indicator start
************************************************************/
.indicator-outer-container {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.indicator-container {
  background: #f5f5f5;
  border-radius: 0.3125rem;
  opacity: 1;
  padding: 0.5rem;
  display: flex;
  margin-right: 1rem;
}

.indicator-invalid-text {
  font-weight: bold;
  letter-spacing: 0px;
  color: #12141a;
  opacity: 0.3;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.indicator-valid-text {
  font-weight: bold;
  letter-spacing: 0px;
  color: #16951c;
  opacity: 1;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.indicate-tick-image {
  display: block;
  width: auto;
  height: auto;
  object-fit: contain;
}

/************************************************************
password indicator end
************************************************************/

/************************************************************
member component start
************************************************************/
.terms-condition-box {
  display: flex;
  flex-direction: column;
  background: transparent
    linear-gradient(
      90deg,
      var(--member-outer-container-gradient-front) 0%,
      var(--member-outer-container-gradient-back) 100%
    )
    0% 0% no-repeat padding-box;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  font-weight: 800;
  color: gray;
}

.member-info-outer-container {
  display: flex;
  background: transparent
    linear-gradient(
      90deg,
      var(--member-outer-container-gradient-front) 0%,
      var(--member-outer-container-gradient-back) 100%
    )
    0% 0% no-repeat padding-box;
  padding: 2rem;
  flex-direction: row;
}

.member-view-outer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 2.1875rem;
}

.profile-avatar {
  color: #fff;
  background-color: #adb5bd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border-radius: 50% !important;
  margin-top: auto;
  margin-bottom: auto;
}

.profile-edit-icon {
  cursor: pointer;
}

.member-view-container {
  display: flex;
  flex-direction: column;
  padding-left: 1.4375rem;
  justify-content: center;
}

.user-name-title {
  font-weight: 800;
  font-size: 1.875rem;
  color: #24293a;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0.75rem;
}

.member-date-title {
  font-weight: 600;
  font-size: 1rem;
  color: #24293a;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0.75rem;
}

.edit-profile {
  font-weight: 600;
  font-size: 1rem;
  color: #24293a;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
}

.point-outer-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 1.875rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 2.1875rem;
  position: relative;
}

.point-outer-container-stack-top {
  z-index: 9;
  position: absolute;
  background: rgb(175, 170, 170);
  border-radius: 20px;
  opacity: 0.6;
  padding: 1.875rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.point-loading-view {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  display: flex;
  z-index: 100;
}

.points {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.c-point {
  font-weight: 800;
  color: var(--button-background-color);
}
.your-point-text {
  font-size: 1rem;
  font-weight: 600;
  color: #24293a;
  opacity: 0.5;
  letter-spacing: 0px;
  margin-bottom: 0.875rem;
}

.total-point-text {
  font-size: 1.875rem;
  font-weight: 800;
  color: #24293a;
  opacity: 1;
  letter-spacing: 0px;
}

.circle-logo {
  align-items: flex-end;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.tier-outer-container {
  background: transparent
    linear-gradient(
      117deg,
      var(--tier-outer-container-gradient-front) 0%,
      var(--tier-outer-container-gradient-back) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  padding: 1.875rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.tier-outer-container-stack-top {
  z-index: 9;
  position: absolute;
  background: rgb(175, 170, 170);
  border-radius: 20px;
  opacity: 0.6;
  padding: 1.875rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.your-tier-text {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0px;
  color: #fff;
  opacity: 0.5;
  margin-bottom: 0.875rem;
}

.tier-level-text {
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-weight: bold;
  font-size: 1.375rem;
}

.tier-image {
  margin-right: 0.875rem;
  height: 1.2rem;
  width: auto;
}

.progress-container {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.progress {
  background-color: #fff;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  height: 6px;
  background-color: #b8b7b6 !important;
}

.progress-bar {
  background-color: #fff !important;
  opacity: 1 !important;
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
}

.progress-tier-desc {
  letter-spacing: -0.15px;
  color: #ffffff;
  opacity: 1;
}

.progress-point {
  font-weight: 800;
  color: #fff;
  font-size: 0.9375rem;
}
/************************************************************
member component end
************************************************************/

/************************************************************
profile start
************************************************************/
.profile-outer-container {
  background-color: #f0f1f6;
  padding: 1rem;
  display: flex;
  flex: 1;
}

.profile-container {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.profile-container .back-icon {
  margin-right: 1rem;
  cursor: pointer;
}

.profile-info-container {
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.profile-info-container .avatar {
  color: #fff;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
  height: 108px;
  width: 108px;
  border-radius: 50% !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
}
.avatar-container {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.camera-icon {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: -20px;
  cursor: pointer;
}

.change-password-text {
  text-decoration: underline;
  font-weight: Bold;
  font-size: 0.9375rem;
  letter-spacing: 0px;
  color: #347dfd;
  opacity: 1;
  margin-top: 2.25rem;
  cursor: pointer;
  margin-right: 1rem;
}

.profile-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  margin-top: 1rem;
}

.back-button {
  color: var(--profile-back-text);
  width: 20%;
  margin-right: 0.5rem;
}
.save-button {
  color: var(--profile-back-text);
  width: 20%;
  margin-right: 0rem !important;
}

.change-password-modal-header-text {
}

.change-password-modal-header-text h5 {
  font-weight: bold;
  font-size: 1.25rem;
  color: #24293a;
}

.change-password-modal-body-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #24293a;
}

.change-password-modal-footer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.change-password-modal-positive-button {
  width: 20%;
  background-color: #af8e56 !important;
  color: #fff !important;
}

.change-password-modal-negative-button {
  width: 20%;
  background-color: #fff !important;
  color: #af8e56 !important;
  border: solid 1px #af8e56 !important;
}

.profile-tab-text {
  font-weight: Bold;
  font-size: 0.9375rem;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  /* margin-top: 2.25rem; */
  cursor: pointer;
  margin-right: 1rem;
}

.profile-tab-text-active {
  text-decoration: underline;
  font-weight: Bold;
  font-size: 0.9375rem;
  letter-spacing: 0px;
  color: var(--point-history-bottom-underline);
  opacity: 1;
  /* margin-top: 2.25rem; */
  cursor: pointer;
  margin-right: 1rem;
  text-decoration-color: var(--point-history-bottom-underline);
}
/************************************************************
profile end
************************************************************/

/************************************************************
transaction start
************************************************************/
.transaction-title {
  color: #24293a;
  font-size: 1.5625rem;
  font-weight: bold;
  margin-bottom: 2.375rem;
}

.transaction-component-outer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* justify-content: space-between; */
  padding-right: 0.3125rem;
}

.transaction-component-info-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
  flex: 0.9;
}

.trans-type {
  font-weight: bold;
  font-size: 0.9375rem;
  color: #24293a;
}

.trans-date {
  font-weight: bold;
  font-size: 0.8125rem;
  color: #24293a;
}

.trans-desc {
  font-weight: 600;
  color: #24293a;
  font-size: 0.75rem;
  opacity: 0.5;
  letter-spacing: -0.0075rem;
  text-align: justify;
}

.trans-pts-add-container {
  background: #ebf7e7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #16951c;
  font-size: 0.9375rem;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  white-space: break-spaces;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  align-self: center;
  width: 0.1;
  flex: 0.1;
  display: flex;
  justify-content: center;
}

.trans-pts-minus-container {
  background: #f7ebe7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #d82929;
  font-size: 0.9375rem;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  white-space: break-spaces;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  align-self: center;
  flex: 0.1;
  display: flex;
  justify-content: center;
}
/************************************************************
transaction end
************************************************************/
/************************************************************************************
not found start
************************************************************************************/
.not-found-container {
  background: white;
  border-radius: 5px;
  display: flex;
  padding: 1rem;
  height: 100vh;
  flex-direction: column;
  flex: 1;
}

.not-found-external-layout {
  flex: 1;
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.not-found-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #24293a;
  margin-bottom: 2vw;
}

.not-found-subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #24293a;
  opacity: 0.6;
  margin-bottom: 2vw;
}

.not-found-desc {
  font-size: 0.875rem;
  font-weight: 300;
  color: #24293a;
  opacity: 0.6;
  margin-bottom: 2vw;
}

.not-found-container {
  background-color: #fff;
}

.homepage-button {
  width: fit-content;
}
/************************************************************************************
not found end
************************************************************************************/

.address-block-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
}
